<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="560px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
        <el-form size="small" ref="inputForm" :model="inputForm" :rules="rules" label-width="110px">
            <el-form-item label="任务名称" prop="jobName">
                <el-input v-model.trim="inputForm.jobName"
                          :disabled="inputType"
                          placeholder="请输入任务名称(限20字)"
                          maxlength="20" clearable></el-input>
            </el-form-item>
            <el-form-item label="比对模型" prop="modelLabel">
                <el-select v-model="inputForm.modelLabel"
                           :disabled="inputType"
                           placeholder="请选择比对模型"
                           style="width: 100%"
                >
                    <el-option
                            v-for="item in contrastModelList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="数值设定(MB)" prop="imgMin">
                <el-col :span="11">
                    <el-input v-model.trim.number="inputForm.imgMin"
                              :disabled="inputType"
                              @input="(value)=>{jiage(value,'imgMin')}"
                              @blur="setNumerical('imgMin','imgMax',1)"
                              placeholder="最小值(0~10)"
                              maxlength="3" clearable></el-input>
                </el-col>
                <el-col class="text_center" :span="2">-</el-col>
                <el-col :span="11">
                    <el-input v-model.trim.number="inputForm.imgMax"
                              :disabled="inputType"
                              @input="(value)=>{jiage(value,'imgMax')}"
                              @blur="setNumerical('imgMin','imgMax',2)"
                              placeholder="最大值(0~10)"
                              maxlength="3" clearable></el-input>
                </el-col>
<!--                <el-col :span="5" :offset="1">-->
<!--                    <el-select v-model="inputForm.sizeUnit"-->
<!--                               :disabled="inputType"-->
<!--                               placeholder="单位"-->
<!--                               style="width: 100%"-->
<!--                    >-->
<!--                        <el-option-->
<!--                                v-for="item in unitList"-->
<!--                                :key="item.value"-->
<!--                                :label="item.label"-->
<!--                                :value="item.value">-->
<!--                        </el-option>-->
<!--                    </el-select>-->
<!--                </el-col>-->
            </el-form-item>

            <el-form-item label="定时启动时间" prop="modelStartUpTime">
                <el-date-picker
                        v-model="inputForm.modelStartUpTime"
                        type="datetime"
                        :disabled="inputType"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        style="width: 100%"
                        clearable
                        placeholder="请选择定时启动时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="任务描述" prop="remake">
                <el-input v-model.trim="inputForm.remake"
                          :disabled="inputType"
                          placeholder="请输入任务描述(限30字)"
                          maxlength="30" clearable></el-input>
            </el-form-item>
            <el-form-item label="对照图片集合" prop="imgList">
                <resume-upload ref="resume-upload" :disabledBut="inputType" :uploadNum="imgFileNumr" :filtButton="true"
                               :upFileSize="upFileSize"
                               :allowFiles="imgPattern"
                               @upFileDate="(data)=>getUpData(data,1)" :upDateType="'img'"></resume-upload>

                <div class="flex_l_c" style="flex-wrap: wrap">
                    <div
                            v-for="(item,index) in inputForm.imgList" :key="item.id"
                            style="position: relative;padding-bottom: 10px;width: 110px;margin-right: 15px"
                    >
                        <div class="el-icon-error" @click="deleteFile(item,index)" v-if="pageType != 1 && !inputType"
                             style="position:absolute; top: -8px;right: -8px;font-size: 16px;z-index: 99;cursor: pointer"></div>
                        <el-image
                                style="width: 100%; height: 110px;"
                                :src="item.netUrl"
                                :fit="'cover'"
                                :preview-src-list="[item.netUrl]"
                        >
                            <div slot="error" class="image-slot">
                                <el-image
                                        style="width: 100%; height: 110px;"
                                        :src="require('@/assets/img/default.png')"
                                        :fit="'cover'">
                                </el-image>
                            </div>
                        </el-image>
                        <el-tooltip class="item" effect="dark" :content="item.fileName" placement="top">
                            <div style="font-size: 12px;width: 100%" class="omit">{{ item.fileName }}</div>
                        </el-tooltip>
                    </div>
                </div>
            </el-form-item>
            <el-form-item v-if="pageType == 1" label="符合条件的比对结果" label-width="140px" prop="resuleFiles">
                <div class="flex_l_c" style="flex-wrap: wrap">
                    <div
                            v-for="(item,index) in inputForm.resuleFiles" :key="item.id"
                            style="position: relative;padding-bottom: 10px;width: 110px;margin-right: 15px"
                    >
                        <el-image
                                style="width: 100%; height: 110px;"
                                :src="item.netUrl"
                                :fit="'cover'"
                                :preview-src-list="[item.netUrl]"
                        >
                            <div slot="error" class="image-slot">
                                <el-image
                                        style="width: 100%; height: 110px;"
                                        :src="require('@/assets/img/default.png')"
                                        :fit="'cover'">
                                </el-image>
                            </div>
                        </el-image>
                        <el-tooltip class="item" effect="dark" :content="item.fileName" placement="top">
                            <div style="font-size: 12px;width: 100%" class="omit">{{ item.fileName }}</div>
                        </el-tooltip>
                        <div class="text_center">
                            <el-button size="mini" type="primary" @click="download(item)">下载</el-button>
                        </div>
                    </div>
                </div>
            </el-form-item>
        </el-form>
        <div v-if="pageType != 1" class="text_center">
            <el-button size="small" @click="handleClose()">取消</el-button>
            <el-button size="small" type="primary" @click="sureData()" v-no-more-click>保存</el-button>
        </div>
        <div v-else class="text_center">
            <el-button size="small" @click="handleClose()">返回</el-button>
        </div>
    </el-dialog>
</template>

<script>
import resumeUpload from "@/components/Upload/resumeUpload.vue";

export default {
    name: "ImageComparisonAdd",
    components: {resumeUpload},
    data() {
        return {
            title: '',
            dialogVisible: false,

            inputForm: {
                jobName: '',
                modelLabel: '图片大小比对',
                imgMin: '',
                imgMax: '',
                sizeUnit: 'MB',
                modelStartUpTime: '',
                remake: '',
                imgList: [],
            },
            rules: {
                jobName: [
                    {required: true, message: '请输入任务名称', trigger: 'blur'},
                ],
                modelLabel: [
                    {required: true, message: '请选择比对模型', trigger: 'change'},
                ],
                imgList: [
                    {type: 'array', required: true, message: '请上传对照图片', trigger: 'change'},
                ],
            },
            contrastModelList: [
                {
                    label: '图片大小比对',
                    value: '图片大小比对',
                },
            ],
            unitList: [
                // {
                //     value: 'KB',
                //     label: 'KB',
                // },
                {
                    value: 'MB',
                    label: 'MB',
                },
                // {
                //     value: 'GB',
                //     label: 'GB',
                // },
            ],
            inputType: false,
            pageType: 0,
            imgFileNumr: 10,
            upFileSize: 10,
            imgPattern: ['JPG', 'PNG', 'JPEG']
        }
    },

    methods: {
        init(num, row) {
            this.inputType = false
            this.pageType = num
            if (num == 0) {
                this.title = '  新增图片比对任务'
                this.dialogVisible = true
            } else {
                if (num == 1) {
                    this.title = '查看图片比对任务'
                    this.inputType = true
                } else {
                    this.title = '修改图片比对任务'
                }
                this.$axios(this.api.original.dyygimgmodelGetById + row.id, {}, 'get').then((res) => {
                    if (res.status) {
                        this.inputForm = res.data
                        this.$set(this.inputForm, 'imgList', res.data.files)
                        this.dialogVisible = true
                        this.$nextTick(() => {
                            this.$refs['resume-upload'].setOldFile(this.inputForm.imgList)
                        })
                        // if (this.toDateSize(new Date(), this.inputForm.modelStartUpTime)) {
                        //     this.inputType = true
                        // }
                    }
                })
            }
        },

        //获取上传文件
        getUpData(data, num) {
            this.inputForm.imgList = data.map((item, index) => {
                if (item.upDate) {
                    this.$set(item.upDate, 'fileID', item.id)
                    this.$set(item.upDate, 'fileSort', index + 1)
                    this.$set(item.upDate, 'masterView', 1)
                    this.$set(item.upDate, 'fileSize', item.origSize)
                    return item.upDate
                } else {
                    return item
                }
            })
        },

        deleteFile(row, index) {
            this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.inputForm.imgList.splice(index, 1)
                this.$refs["resume-upload"].deleteFile(row.fileID ? row.fileID : row.id)
            }).catch(() => {
            })
        },

        jiage(value, name) {
            // 使用正则表达式来限制输入为正数
            value = value.replace(/[^\d]/g, ''); //清除"数字"以外的字符
            if (value > 10){
                value = 10
            }
            this.inputForm[name] = value
        },

        setNumerical(name1, name2, num) {
            console.log('==================')
            console.log(this.inputForm[name1])
            console.log(this.inputForm[name2])
            console.log(this.num)
            if (this.inputForm[name1] && this.inputForm[name1] !== 0 && this.inputForm[name2] && this.inputForm[name2] !== 0) {
                if (this.inputForm[name1] * 1 > this.inputForm[name2] * 1) {
                    if (num == 1) {
                        this.inputForm[name1] = ''
                    } else {
                        this.inputForm[name2] = ''
                    }
                }
            }
        },

        //时间大小比较
        toDateSize(date1, date2) {
            date1 = new Date(date1).getTime()
            date2 = new Date(date2).getTime()
            if (date1 > date2) {
                return true
            } else {
                return false
            }
        },

        //下载图片
        async download(row) {
            // 获取图片对象和画布对象
            const imgUrl = row.netUrl
            const response = await fetch(imgUrl)
            const blob = await response.blob()
            // 创建下载链接
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.download = 'image'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            // 释放 Blob URL
            window.URL.revokeObjectURL(url)
        },

        //保存
        sureData() {
            if (this.inputForm.modelStartUpTime && this.toDateSize(new Date(), this.inputForm.modelStartUpTime)) {
                this.$message.error('启动时间必须大于当前时间')
                return
            }
            this.$refs.inputForm.validate((valid) => {
                if (valid) {
                    if (this.inputForm.modelStartUpTime && this.toDateSize(new Date(), this.inputForm.modelStartUpTime)) {
                        this.$message.error('已经启动的数据不允许被修改')
                        return
                    }
                    this.$axios(this.api.original.dyygimgmodelSaveOrUpdate, {
                        ...this.inputForm,
                        files: this.inputForm.imgList,
                    }, 'post').then((res) => {
                        if (res.status) {
                            this.$message.success('保存成功')
                            this.$emit('refreshList')
                            this.handleClose()
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        handleClose() {
            if (this.inputForm.imgList.length) {
                this.inputForm.imgList.forEach(item => {
                    this.$refs["resume-upload"].deleteFile(item.fileID ? item.fileID : item.id, '', 1)
                })
                this.inputForm.imgList = []
            }
            this.$refs.inputForm.resetFields()
            this.inputForm = {
                jobName: '',
                modelLabel: '图片大小比对',
                imgMin: '',
                imgMax: '',
                sizeUnit: 'KB',
                modelStartUpTime: '',
                remake: '',
                imgList: [],
            }
            this.dialogVisible = false
        },
    },
}
</script>

<style scoped>

</style>